<template>
  <v-card class="pa-4">
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn @click.stop="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div class="d-flex align-center mb-2" v-if="custom">
      <div class="d-flex flex-grow-1">
        <v-text-field
          dense
          hide-details="auto"
          filled
          label="Importe"
          suffix="€"
          v-model="importeCustom"
          :rules="[rules.req, rules.isNumber]"
          class="flex-grow-1"
        ></v-text-field>
        <v-autocomplete
          dense
          filled
          hide-details
          :items="clientes"
          item-color="primary"
          item-text="search"
          item-value="IdCliente"
          v-model="idCliente"
          class="flex-grow-1 ml-2"
          label="Cliente"
        >
        </v-autocomplete>
        <!-- <v-text-field
          class="flex-grow-0 ml-2"
          dense
          hide-details="auto"
          filled
          label="IdCliente"
          v-model="idCliente"
        ></v-text-field> -->
      </div>
      <v-btn color="primary" class="ml-2" @click.stop="pagoLink">Generar</v-btn>
    </div>

    <v-btn
      :loading="link == ''"
      outlined
      @click.stop="copy"
      class="text--button"
    >
      {{ link }}
      <v-icon right>mdi-content-copy</v-icon>
    </v-btn>
    <div class="d-flex mt-2" style="gap: 5px">
      <v-alert
        v-for="field in errorFields"
        type="error"
        dense
        text
        class="mb-0"
        >{{ field }}</v-alert
      >
    </div>
    <v-subheader>Válido durante 24h - {{ total }} €</v-subheader>
  </v-card>
</template>

<script>
import { req, isNumber } from '@/utils/validations.js'

export default {
  props: {
    impagados: Array,
    custom: Boolean,
  },
  data() {
    return {
      link: "",
      idCliente: null,
      errorFields: [],
      importeCustom: null,
      clientes: [],
      rules: { req, isNumber }
    };
  },
  computed: {
    total() {
      if (this.custom) return this.importeCustom;
      return this.impagados.reduce((t, c) => t + Number(c.ImporteTotal), 0);
    },
  },
  mounted() {
    if (this.impagados.length == 0 && !this.custom) {
      this.$emit("close");
      return;
    }
    if (!this.custom)
      this.generarLink();
    else
      this.getClientes();
  },
  methods: {
    copy() {
      try {
        navigator.clipboard.writeText(this.link.trim());
        this.$root.$emit("snack", "Enlace copiado");
      } catch (err) {
        console.error(err);
        this.$root.$emit("snack", "No se ha podido copiar el enlace");
      }
    },
    generarLink() {
      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/stripe/stripeInit.php`,
        method: "POST",
        headers: {
          Authorization: this.$store.getters.getJwtEmpresa,
        },
        data: {
          facturas: this.impagados.map((x) => x.IdCarteraCobro),
          IdCliente: this.impagados[0].IdCliente,
        },
      })
        .then((res) => {
          this.link = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido generar el link");
        });
    },
    pagoLink() {
      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/stripe/stripeInit.php`,
        method: "POST",
        headers: {
          Authorization: this.$store.getters.getJwtEmpresa,
        },
        data: {
          importe: this.importeCustom,
          IdCliente: this.idCliente,
        },
      })
        .then((res) => {
          this.link = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido generar el link");
        });
    },
    getClientes() {
      return new Promise(async (resolve, reject) => {
        let str = this.$store.getters.getDatosEmpresa;

        const { IdAgente } = str.iss;

        const { data } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/clientes.php`,
          method: "GET",
          params: {
            token: this.$store.getters.getJwtEmpresa,
            IdAgente,
          },
        });

        this.clientes = data.map(cli => {
          return {
            IdCliente: cli.IdCliente,
            search: `${cli.Identidad} - ${cli.RazonSocial || [cli.Apellido1, cli.Apellido2, cli.Nombre].filter((i) => !!i).join(" ").trim()}`
          }
        });
        resolve()
      });
    },
  },
};
</script>
